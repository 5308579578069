import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "create-react-app"
    }}>{`Create React App`}</h1>
    <p>{`Please note there’s a `}<a parentName="p" {...{
        "href": "https://github.com/facebook/create-react-app/issues/5580"
      }}>{`known bug`}</a>{` with
the macro so live reloading doesn’t
currently work.`}</p>
    <p>{`With Create React App you will need to use
`}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/mdx.macro"
      }}><inlineCode parentName="a">{`mdx.macro`}</inlineCode></a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npx create-react-app my-app
yarn add mdx.macro
`}</code></pre>
    <p>{`Then create the following `}<inlineCode parentName="p">{`src/App.js`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// src/App.js

import React, { lazy, Component, Suspense } from 'react';
import { importMDX } from 'mdx.macro';

const Content = lazy(() => importMDX('./Content.mdx'));

class App extends Component {
  render() {
    return (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Content />
        </Suspense>
      </div>
    );
  }
}

export default App;
`}</code></pre>
    <p>{`And then create the following `}<inlineCode parentName="p">{`src/Content.mdx`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`# Hello, world!
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://github.com/mdx-js/mdx/tree/master/examples/create-react-app"
      }}>{`See the full example`}</a></p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      